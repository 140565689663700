import _merge from "./lib/util/merge";
import _xlink from "./lib/xlink";
import _xml from "./lib/xml";
import _xmlns from "./lib/xmlns";
import _aria from "./lib/aria";
import _svg from "./lib/svg";
var exports = {};
var merge = _merge;
var xlink = _xlink;
var xml = _xml;
var xmlns = _xmlns;
var aria = _aria;
var svg = _svg;
exports = merge([xml, xlink, xmlns, aria, svg]);
export default exports;